<template>
  <div class="footer">
    <p class="footer__txt">© {{ year }} Letique </p>
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return (new Date()).getFullYear();
    },
  },
};
</script>
